import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import Approach from "../../assets/img/bg-approach.png";
import DividerOrange from "../../assets/img/deviderOrange.png";
import Img from "../../assets/img/person.png";
import { getTeammembers } from "../../services/userService";

const AboutUs = () => {
  // Initialize teamData as an empty array and add loading/error state
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch team members when the component mounts
  useEffect(() => {
    getTeammembers()
       
      .then((response) => {
        
        setTeamData(response || []); // Ensure response data is an array
        setLoading(false); // Stop loading when data is fetched
      })
      .catch((error) => {
        setError("Error fetching team members");
        setLoading(false); // Stop loading on error
      });
  }, []);

  const TextStyling = (props) => {
    if (typeof props.text !== "string") {
      console.error("Expected a string");
      return null; // Handle the error appropriately
    }
    const [firstWord, ...remainingWords] = props.text.split(" ");
    const remainingText = remainingWords.join(" ");

    return (
      <div className={props.class}>
        <span className="first">{firstWord}</span>
        <span className="remaining" style={props.style}>
          {remainingText}
        </span>
      </div>
    );
  };

  const teamList = (data) => {
    if (!Array.isArray(data)) {
      return <p>No team members found.</p>; // Fallback for non-array data
    }

    return data.map((item, i) => (
      <li className="list-group-item d-flex align-items-start" key={i}>
        <img
          src={item.imageUrl}
          alt="Team Member"
          className="me-3 team-img"
        />
        <div>
          <h2>{item.name}</h2>
          <h4>{item.designation}</h4>
          <p>{item.description}</p>
        </div>
      </li>
    ));
  };

  return (
    <div
      className="container-fluid text-center g-0"
      style={{ backgroundColor: "var(--bg-pink)" }}
    >
      <div className="approach-wrapper">
        <div className="approach">
          <TextStyling
            text={"Our APPROACH"}
            class={"text-container-center"}
            style={{ color: "var(--primary)" }}
          />
          <p className="intro">
            We are a unique blend of theoretical knowledge and hands-on
            experience. Our curriculum is designed to cater to traders of all
            levels - from beginners just starting out to seasoned professionals
            looking to refine their strategies. Each course is structured to
            provide a balanced mix of lecture exercises and real-time trading
            simulation.
          </p>
          <img
            src={Approach}
            alt="Approach"
            className="img-fluid img-radious"
          />
        </div>
        <img
          src={DividerOrange}
          alt="Divider"
          className="dividerOrange img-fluid"
        />
      </div>

      <div
        className="team-wrapper"
        style={{ backgroundColor: "var(--primary)", marginTop: "-0.7rem" }}
      >
        <TextStyling
          text={"Meet OUR TEAM"}
          class={"text-container-center"}
          style={{ color: "var(--white)" }}
        />

        <div className="team-list">
          <ul className="list-group">
            {/* Show loading, error, or the team list */}
            {loading && <p>Loading team members...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && teamList(teamData)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
